// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bj_kw";
export var caseStudyBackground__bgColor = "bj_ks";
export var caseStudyBackground__lineColor = "bj_kt";
export var caseStudyHead = "bj_kn";
export var caseStudyHead__imageAbsolute = "bj_kq";
export var caseStudyHead__imageWrapper = "bj_kp";
export var caseStudyProjectsSection = "bj_kx";
export var caseStudyQuote__bgRing = "bj_kr";
export var caseStudyTechSection = "bj_kv";
export var caseStudy__bgDark = "bj_km";
export var caseStudy__bgLight = "bj_kl";